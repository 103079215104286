
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "RedPackage",
  emits:['close'],
  props: {
    src: {
      type: String,
      default: "",
    },
    
    target: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, ctx) {
    const router = useRouter();
    function onOpenPackage() {
      router.push({
        name: props.target,
        query: props.params,
      });
      ctx.emit('close')
    }
    function close() {
       ctx.emit('close')
    }
    return {
      onOpenPackage,
      close
    };
  },
});
