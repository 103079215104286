
  import { defineComponent, ref, reactive } from "vue";

  import MenuTab from "@/components/common/MenuTab.vue";
  import Empty from "@/components/common/Empty.vue";
  import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
  import UiCheckbox from "@/components/UI/Checkbox";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import { calendarFormat} from "@/utils/dateFormat";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
  import wechatLogin from "@/api/wechatLogin";
  import list from "@/components/common/list.vue";
  import InvoiceItem from "@/components/common/InvoiceItem.vue";
  import RedPackage from "@/components/common/RedPackage.vue";

  interface CustomObject {
    [key: string]: any;
  }

  export default defineComponent({
    name: "Invoice",
    components: {
      MenuTab,
      UiCheckbox,
      UiCheckboxGroup,
      Empty,
      Loading,
      RedPackage,
       list,
      InvoiceItem
    },
    setup() {
      type Menu = "申请发票" | "已申请";
      const menu: Menu[] = ["申请发票", "已申请"];
      const route = useRoute();
      const currentMenuItem = ref(menu[0]);
      const showRedPackage = ref(
              localStorage.getItem("showRedPackage") ? true : false
      );
      localStorage.removeItem("showRedPackage");
      if (route.query.type) {
        currentMenuItem.value = menu[1];
      }

      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      const invoice = ref(null);
    const loading = ref(true);
       wechatLogin(route.query.code as string, route.query.state as string).then(
      (res) => {
        axios
          .get("/M/AokeNei/GetNoApplyInvoice", { params: { validate: true } })
          .then((res) => {
            if (res.data.success) {
              invoice.value = res.data.obj.orders;
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
            loading.value = false;
          })
          .catch((err) => {
            console.log("err");
            loading.value = false;
          });
      }
    );

      const checked = ref(false);
      const expressTrace = ref<CustomObject>([]);
      const flag = ref(true);
      const checked1 = reactive<string[]>([]);

      // function onViewWuliu(index: any) {
      //   // orderpost.value[index].kd = expressTrace.value.traces
      // }


      const changeBox = (val: boolean) => {
      if (val) {
        ((invoice.value as unknown) as []).forEach((item) => {
          checked1.push((item as any).ono);
        });
      } else {
        checked1.length = 0;
      }
    };
    const changeBoxGroup = (val: any) => {
      if (val && invoice.value) {
        if (val.length == ((invoice.value as unknown) as []).length) {
          checked.value = true;
        } else {
          checked.value = false;
        }
      }
    };
      const loadOption = {
        text: "加载中...",
        color: "#b9135a",
        textColor: "#b9135a",
      };
      const router = useRouter();
      function onApply(type: number) {
        if (!checked1.length) {
          Toast({
            title: "请选择要申请发票的订单",
            type: "warning",
          });
        } else {
          // console.log("fapiaoBid", JSON.stringify(checked1));
          if(type && type==1&&checked1.length>1){//单开
            Toast({
              title: "单个开票不可多选哦~",
              type: "warning",
            });
          }else{
            sessionStorage.setItem("fapiaoBid", JSON.stringify(checked1));
            sessionStorage.setItem("openInvoiceType", JSON.stringify(type));
            router.push({
              name: "ApplyInvoice",
            });
          }
        }
      }
      onBeforeRouteLeave(() => {
        showRedPackage.value = false;
        return true;
      });

      function onClosePackage() {
        showRedPackage.value = false;
      }

      return {
        menu,
      currentMenuItem,
      selectMenu,
      invoice,
      calendarFormat,
      checked,
      checked1,
      flag,
      changeBox,
      changeBoxGroup,
      loadOption,
      loading,
      onApply,
      showRedPackage,
      onClosePackage,
      };
    },
  });
