<template>
  <main>
    <menu-tab
      :menu="menu"
      :slidesPerView="2"
      :initIndex="Number($route.query.type) || 0"
      @change="selectMenu"
    ></menu-tab>

    <div v-if="invoice">
      <ui-checkbox-group
        @on-change="changeBoxGroup"
        v-model:value.sync="checked1"
        class="car-ul invoice"
        v-show="currentMenuItem == menu[0]"
      >
        <ui-checkbox
          class="car-li"
          v-for="(item, index) in invoice"
          :label="item.ono"
          :key="index"
          size="large"
        >
          <div class="spend-date">
            <span> {{ calendarFormat(item.odate)[2] }}</span>
            <p>
              {{ calendarFormat(item.odate)[0] }}/{{ calendarFormat(item.odate)[1] }}
            </p>
          </div>
          <div>
            <div class="order-date">订单编号：{{ item.ono }}</div>
            <div class="order-date">订单金额：￥{{ item.oin }}</div>
            <div class="order-date">汇入账户：{{ item.opay }}</div>
          </div>
          
        </ui-checkbox>
      </ui-checkbox-group>
    </div>

    <list url="/M/AokeNei/GetAlreadyApplyInvoice" :show="currentMenuItem == menu[1]">
      <template v-slot:default="data">
        <invoice-item :invoiced="data"></invoice-item>
      </template>
    </list>

    <!-- v-if="showRedPackage" 
    <red-package
      v-if="false"
      @close="onClosePackage"
      :src="require('../assets/img/hongbao.png')"
      target="NewsDetail"
      :params="{ newsid: 15835 }"
    ></red-package>-->
    <empty
      v-if="
        (currentMenuItem == menu[0] && invoice && !invoice.length)
      "
      :text="currentMenuItem"
    ></empty>
  </main>
  <loading :options="loadOption" v-if="loading"></loading>

  <div
    class="dashboard"
    v-if="currentMenuItem == menu[0] && invoice && invoice.length"
  >
    <ui-checkbox
      label="全选"
      v-model="checked"
      @on-change="changeBox"
      size="large"
      :inputStyle="{ borderColor: '#b9135B' }"
    >
      全选
    </ui-checkbox>

    <div class="pay" v-if="checked1 && checked1.length>1">
      <a href="javascript:;" @click="onApply(2)">合并开票</a>
    </div>
    <div class="pay" v-else>
      <a href="javascript:;" @click="onApply(1)">开票</a>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, reactive } from "vue";

  import MenuTab from "@/components/common/MenuTab.vue";
  import Empty from "@/components/common/Empty.vue";
  import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
  import UiCheckbox from "@/components/UI/Checkbox";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import { calendarFormat} from "@/utils/dateFormat";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
  import wechatLogin from "@/api/wechatLogin";
  import list from "@/components/common/list.vue";
  import InvoiceItem from "@/components/common/InvoiceItem.vue";
  import RedPackage from "@/components/common/RedPackage.vue";

  interface CustomObject {
    [key: string]: any;
  }

  export default defineComponent({
    name: "Invoice",
    components: {
      MenuTab,
      UiCheckbox,
      UiCheckboxGroup,
      Empty,
      Loading,
      RedPackage,
       list,
      InvoiceItem
    },
    setup() {
      type Menu = "申请发票" | "已申请";
      const menu: Menu[] = ["申请发票", "已申请"];
      const route = useRoute();
      const currentMenuItem = ref(menu[0]);
      const showRedPackage = ref(
              localStorage.getItem("showRedPackage") ? true : false
      );
      localStorage.removeItem("showRedPackage");
      if (route.query.type) {
        currentMenuItem.value = menu[1];
      }

      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      const invoice = ref(null);
    const loading = ref(true);
       wechatLogin(route.query.code as string, route.query.state as string).then(
      (res) => {
        axios
          .get("/M/AokeNei/GetNoApplyInvoice", { params: { validate: true } })
          .then((res) => {
            if (res.data.success) {
              invoice.value = res.data.obj.orders;
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
            loading.value = false;
          })
          .catch((err) => {
            console.log("err");
            loading.value = false;
          });
      }
    );

      const checked = ref(false);
      const expressTrace = ref<CustomObject>([]);
      const flag = ref(true);
      const checked1 = reactive<string[]>([]);

      // function onViewWuliu(index: any) {
      //   // orderpost.value[index].kd = expressTrace.value.traces
      // }


      const changeBox = (val: boolean) => {
      if (val) {
        ((invoice.value as unknown) as []).forEach((item) => {
          checked1.push((item as any).ono);
        });
      } else {
        checked1.length = 0;
      }
    };
    const changeBoxGroup = (val: any) => {
      if (val && invoice.value) {
        if (val.length == ((invoice.value as unknown) as []).length) {
          checked.value = true;
        } else {
          checked.value = false;
        }
      }
    };
      const loadOption = {
        text: "加载中...",
        color: "#b9135a",
        textColor: "#b9135a",
      };
      const router = useRouter();
      function onApply(type: number) {
        if (!checked1.length) {
          Toast({
            title: "请选择要申请发票的订单",
            type: "warning",
          });
        } else {
          // console.log("fapiaoBid", JSON.stringify(checked1));
          if(type && type==1&&checked1.length>1){//单开
            Toast({
              title: "单个开票不可多选哦~",
              type: "warning",
            });
          }else{
            sessionStorage.setItem("fapiaoBid", JSON.stringify(checked1));
            sessionStorage.setItem("openInvoiceType", JSON.stringify(type));
            router.push({
              name: "ApplyInvoice",
            });
          }
        }
      }
      onBeforeRouteLeave(() => {
        showRedPackage.value = false;
        return true;
      });

      function onClosePackage() {
        showRedPackage.value = false;
      }

      return {
        menu,
      currentMenuItem,
      selectMenu,
      invoice,
      calendarFormat,
      checked,
      checked1,
      flag,
      changeBox,
      changeBoxGroup,
      loadOption,
      loading,
      onApply,
      showRedPackage,
      onClosePackage,
      };
    },
  });
</script>


<style lang="scss" scoped>
  main {
    padding-bottom: 50px;
  }

  .car-li {
    box-sizing: border-box;
    padding: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    border-bottom: 5.625px solid #f4f4f4;
    position: relative;
    padding-left: 70px;
    margin-right: 0;
  }

  .car-li .checkbox {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 50%;
    margin-top: -10px;
    z-index: 10;
  }
  .car-li .checkbox .car-check {
    position: absolute;

    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
  }

  .order-date {
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px 0 5px 0;
    padding-left: 10px;
    font-size: 14px;
    box-sizing: border-box;
    color: #666;
    position: relative;
  }

  .spend-date {
    position: absolute;
    left: 15px;
    top: 20px;
    color: #666;
  }
  .spend-date span {
    font-size: 21.938px;
    text-align: center;
    display: block;
  }
  .spend-date p {
    font-size: 13.95px;
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
  }
  #download {
    position: absolute;
    right: 15px;
    top: 50px;
    text-align: center;
    width: 80px;
    height: 22.5px;
    border-radius: 15px;
    line-height: 22.5px;
    font-size: 14px;
    color: #b9135a;
    border: solid 1px #b9135a;
  }
  .car-li .checkbox img {
    position: absolute;
    width: 20px;
    height: 20px;
    vertical-align: top;
    left: 0;
    top: 0;
    z-index: 5;
    display: none;
  }
  .car-li .checkbox::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    border: 1px solid #ccc;
    border-radius: 2.513px;
    box-sizing: border-box;
  }
  .car-li .product-pic img {
    width: 75px;
    height: 75px;
    position: absolute;
    left: 48.487px;
    top: 50%;
    margin-top: -37.5px;
  }

  .product-info .product-name {
    width: 16em;
    display: block;
    font-size: 14px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-info .product-no {
    font-size: 13px;
    color: #999;
    width: 16em;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .poroduct-num {
    margin-top: 5px;
  }
  .poroduct-num .input {
    display: inline-block;
    border: 1px solid #eee;
    height: 25px;
    vertical-align: middle;
    box-sizing: border-box;
  }
  .poroduct-num input {
    width: 50px;
    height: 20px;
    outline: none;
    border: none;
    vertical-align: top;
    padding-top: 5px;
    box-sizing: border-box;
    color: #666666;
    text-align: center;
  }
  .poroduct-num .sub {
    display: inline-block;
    position: relative;
    left: 7.987px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    vertical-align: middle;
    background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d13.png")
    no-repeat center center;
  }
  .poroduct-num .add {
    display: inline-block;
    position: relative;
    left: -7.987px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    vertical-align: middle;
    background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d14.png")
    no-repeat center center;
    background-size: 10px 10px;
  }

  .invoice {
    .car-li {
      padding-left: 15px;
      display: flex;
      align-items: center;
      v::deep {
        .ui-checkbox__label {
          display: flex;
          align-items: center;
        }
      }
    }
    .spend-date {
      left: 42px;
      position: static;
    }
  }

  :deep(.zm-checkbox__label) {
    display: flex;
    align-items: center;
    .spend-date span {
      line-height: 29px;
    }
  }

  .dashboard {
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 50px;
    font-size: 14px;
    border-top: 1px solid #f4f4f4;
    line-height: 50px;
    color: #666;
    padding-left: 15px;

    :deep(.zm-checkbox__label) {
      display: inline-block;
    }
  }
  .order-logistics{
    display: block;
    width: 80px;
    height: 25px;
    background: #ffeded;
    text-align: center;
    color: #b9135a;
    line-height: 25px;
    /* float: right; */
    margin-top: 10px;
    border: 1px solid #b9135a;
    border-radius: 20px;
    font-size: 14px;
  }
  .pay {
    position: absolute;
    right: 10px;
    top: 0;
    width: 100px;
    height: 35px;
    background-color: #b9135a;
    color: #fff;
    border-radius: 35px;
    text-align: center;
    line-height: 35px;
    top: 50%;
    margin-top: -17.5px;
  }
  .pay a {
    color: #fff;
  }
  .nei-order-wuliu ul li{
    line-height: 20px;
  }
  .orderDetail_accept {
    font-size: 14px;
    color: #999;
  }
  .logistics-notyet{
    font-size: 16px!important;
    color:#b9135a;
  }

</style>
